import {SelectGrouped} from '@hconnect/uikit'
import {Button, CardActions, CardContent} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import {Field, FieldInputProps, Form, Formik, FormikProps, getIn} from 'formik'
import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAdminPilotUser} from '../../common/hooks/useAdminPilotUser'
import {RoleCard} from '../../components/Role/RoleCard'
import {useCustomerWithoutNumber} from '../../hooks/useCustomer'
import {usePayerWithoutNumber} from '../../hooks/usePayer'
import {
  ADD_CUSTOMER,
  ADD_PAYER,
  GroupedRoleReducerAction
} from '../../modules/GroupedRoleAssignmentReducer'
import {
  CustomersScopes,
  DataScopes,
  GroupedRoleAssignment
} from '../../modules/ManageUsers.selectors'

import {CommentCategories} from './CommentCategories'
import {CustomerOrPayerDropdown} from './CustomerOrPayerDropdown/CustomerOrPayerDropdown'
import {CustomersAndProjectsSites} from './CustomersScopes'
import {FormErrors} from './FormErrors'
import {Hauliers} from './Hauliers'
import {useCustomerAdminOptions} from './hooks/useCustomerAdminOptions'
import {Payers} from './Payers'
import {Plants} from './Plants'
import DataScopeFields from './role-edit/DataScopeFields'
import {checkRequired, payerActivatedRoles} from './utils'

const useStyles = makeStyles(() => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardActions: {
    justifyContent: 'space-between'
  },
  form: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}))

interface RoleEditLayoutProps {
  groupedRoleAssignment: GroupedRoleAssignment
  roleOptions: any[]
  handleSaveCustomerId: (customersScopes: CustomersScopes[]) => void
  dispatchRoleStateUpdate: React.Dispatch<GroupedRoleReducerAction>
  updateRoleAssignments(values: GroupedRoleAssignment): Promise<void>
  handleDiscard(): void
  handleDeleteCustomer(customerAndSites: CustomersScopes): void
  handleDeletePayer(payerId: string): void
  handleDeletePlant(plantId: string): void
  handleDeleteContract(contractid: string): void
  handleDeleteCommentCategory(commentCategory: string): void
  handleSaveContractIds?: (contractIds: string[]) => void
  handleRoleTypeChange(role: string): void
  handleDeleteHaulier(haulierId: string): void
}

export type RoleOptions = {
  countryId: {isSingle: boolean; value?: string; dropdownOptions?: any[]}
  businessLine: {isSingle: boolean; value?: string; dropdownOptions?: any[]}
  orgUnitId: {isSingle: boolean; value?: string; dropdownOptions?: any[]}
}

export const RoleEditLayout: FunctionComponent<RoleEditLayoutProps> = ({
  groupedRoleAssignment,
  roleOptions,
  dispatchRoleStateUpdate,
  updateRoleAssignments,
  handleDiscard,
  handleDeleteCustomer,
  handleDeletePayer,
  handleDeleteCommentCategory,
  handleDeletePlant,
  handleSaveCustomerId,
  handleSaveContractIds,
  handleRoleTypeChange,
  handleDeleteHaulier
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const isUserAdminPilot = useAdminPilotUser()
  const [customerAdminData, setCustomerAdminData] = useState<Record<string, string | undefined>>()
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false)
  const [showAnotherCustomerOrPayerDropdown, setShowAnotherCustomerOrPayerDropdown] =
    useState(false)

  const initState = {
    countryId: {isSingle: false},
    businessLine: {isSingle: false},
    orgUnitId: {isSingle: false}
  }
  const [selectedOptions, setSelectedOptions] = useState<RoleOptions>(initState)
  const {
    businessLineOptions,
    countryIdOptions,
    orgUnitIdOptions,
    refetchCountries,
    refetchOrgUnit
  } = useCustomerAdminOptions(selectedOptions, setSelectedOptions)

  const {commonDataScopes} = groupedRoleAssignment
  const businessLineScope = commonDataScopes['businessLine']

  useEffect(() => {
    if (isUserAdminPilot && businessLineScope) {
      selectedOptions.businessLine.value ? refetchOrgUnit() : refetchCountries()
    }
  }, [businessLineScope])

  // eslint-disable-next-line complexity
  useEffect(() => {
    ;(selectedOptions.businessLine.isSingle || selectedOptions.businessLine.value) &&
      (selectedOptions.countryId.isSingle || selectedOptions.countryId.value) &&
      (selectedOptions.orgUnitId.isSingle || selectedOptions.orgUnitId.value) &&
      countryIdOptions &&
      businessLineOptions &&
      businessLineOptions.length > 0 &&
      orgUnitIdOptions &&
      orgUnitIdOptions.length > 0 &&
      isUserAdminPilot &&
      setCustomerAdminData({
        countryId: selectedOptions.countryId.isSingle
          ? countryIdOptions?.[0].key
          : selectedOptions.countryId.value,
        businessLine: selectedOptions.businessLine.isSingle
          ? businessLineOptions?.[0].key
          : selectedOptions.businessLine.value,
        orgUnitId: selectedOptions.orgUnitId.isSingle
          ? orgUnitIdOptions?.[0].key
          : selectedOptions.orgUnitId.value
      })
  }, [countryIdOptions, businessLineOptions, orgUnitIdOptions, selectedOptions])

  const customerAdminQueryInfo = useCustomerWithoutNumber(
    customerAdminData?.countryId || '',
    customerAdminData?.orgUnitId || '',
    customerAdminData?.businessLine || '',
    !!customerAdminData
  )

  const payerAdminQueryInfo = usePayerWithoutNumber(
    customerAdminData?.countryId || '',
    customerAdminData?.orgUnitId || '',
    customerAdminData?.businessLine || '',
    !!customerAdminData
  )

  const handleAddCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const customerData =
      customerAdminQueryInfo.data &&
      customerAdminQueryInfo.data.length > 0 &&
      customerAdminQueryInfo.data
        .filter((customer) => customer.customerId === event.target.value)
        .map((value) => value.customerId)

    dispatchRoleStateUpdate({
      type: ADD_CUSTOMER,
      payload: customerData?.[0]
    })

    setShowAnotherCustomerOrPayerDropdown(false)
  }

  const handleAddPayer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const payer =
      payerAdminQueryInfo.data &&
      payerAdminQueryInfo.data.length > 0 &&
      payerAdminQueryInfo.data
        .filter((payer) => payer.payerId === event.target.value)
        .map((value) => value.payerId)
    dispatchRoleStateUpdate({
      type: ADD_PAYER,
      payload: payer?.[0]
    })

    setShowAnotherCustomerOrPayerDropdown(false)
  }

  return (
    <Formik
      initialStatus={{type: 'normal'}}
      initialValues={groupedRoleAssignment}
      enableReinitialize
      // eslint-disable-next-line complexity
      onSubmit={async (values, formikActions) => {
        formikActions.setStatus({type: 'normal'})
        const countryId = !selectedOptions.countryId.isSingle
          ? selectedOptions.countryId?.value || ''
          : countryIdOptions?.[0]?.key || ''
        const businessLine = !selectedOptions.businessLine.isSingle
          ? selectedOptions.businessLine?.value || ''
          : businessLineOptions?.[0]?.key || ''
        const orgUnitId = !selectedOptions.orgUnitId.isSingle
          ? selectedOptions.orgUnitId?.value || ''
          : orgUnitIdOptions?.[0]?.key || ''
        const {
          roleType,
          variantDataScopes: {customersScopes, payerIds}
        } = values

        if (customerAdminQueryInfo.data && customerAdminQueryInfo.data?.length > 0) {
          values.commonDataScopes['countryId'] = countryId
          values.commonDataScopes['businessLine'] = businessLine
          values.commonDataScopes['orgUnitId'] = orgUnitId
        }

        if (
          values.commonDataScopes['areaId'] &&
          (values.commonDataScopes['countryId'] || values.commonDataScopes['orgUnitId'])
        ) {
          delete values.commonDataScopes.areaId
        }

        const neitherCustomerNorPayerPresent =
          customersScopes.length === 0 && (!payerIds || payerIds?.length === 0)

        if (
          payerActivatedRoles.includes(roleType) &&
          roleType !== 'SALES_AGENT' &&
          neitherCustomerNorPayerPresent
        ) {
          formikActions.setStatus({
            type: 'error',
            error: t('roleAssignment.customerOrPayerRequired')
          })
          return
        }

        try {
          await updateRoleAssignments(values)
        } catch (err) {
          formikActions.setStatus({type: 'error', error: err})
        } finally {
          formikActions.setSubmitting(false)
        }
      }}
    >
      {
        // eslint-disable-next-line complexity
        (form) => (
          <RoleCard>
            <Form id="edit-role-form" className={classes.form}>
              <CardContent className={classes.cardContent}>
                <Field
                  name="roleType"
                  validate={checkRequired(true, false, t('roleAssignment.roleRequired'))}
                >
                  {({
                    field,
                    form
                  }: {
                    field: FieldInputProps<string[]>
                    form: FormikProps<{commonDataScopes: DataScopes}>
                  }) => (
                    <SelectGrouped
                      {...field}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange(event)
                        handleRoleTypeChange(event.target.value)
                      }}
                      label={t('roleAssignment.role')}
                      required
                      margin="dense"
                      error={!!getIn(form.errors, 'roleType')}
                      helperText={getIn(form.errors, 'roleType')}
                      options={roleOptions}
                      caption={field.value && t(`roleAssignment.roleTypesSubtitles.${field.value}`)}
                    />
                  )}
                </Field>
                <DataScopeFields
                  form={form}
                  groupedRoleAssignment={groupedRoleAssignment}
                  dispatchRoleStateUpdate={dispatchRoleStateUpdate}
                  customerAdminData={customerAdminData}
                  setShowCustomerDropdown={setShowCustomerDropdown}
                  showCustomerDropdown={showCustomerDropdown}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
                <FormErrors form={form} />
                <CustomersAndProjectsSites
                  groupedRoleAssignment={groupedRoleAssignment}
                  isEditing
                  handleDeleteCustomer={handleDeleteCustomer}
                  handleSaveCustomerId={handleSaveCustomerId}
                  handleSaveContractIds={handleSaveContractIds}
                  setShowAnotherCustomerOrPayerDropdown={setShowAnotherCustomerOrPayerDropdown}
                  setShowCustomerOrPayerDropdown={setShowCustomerDropdown}
                />
                {!showAnotherCustomerOrPayerDropdown &&
                  groupedRoleAssignment.variantDataScopes.customersScopes.length > 0 &&
                  customerAdminQueryInfo.data &&
                  customerAdminQueryInfo.data?.length > 1 && (
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<AddIcon />}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        textTransform: 'none',
                        marginTop: '8px'
                      }}
                      onClick={() => setShowAnotherCustomerOrPayerDropdown(true)}
                    >
                      {t('roleAssignment.addOtherCustomers')}
                    </Button>
                  )}
                {showAnotherCustomerOrPayerDropdown &&
                  customerAdminQueryInfo.data &&
                  (!groupedRoleAssignment.variantDataScopes.payerIds ||
                    groupedRoleAssignment.variantDataScopes.payerIds.length === 0) &&
                  groupedRoleAssignment.variantDataScopes.customersScopes.length <
                    customerAdminQueryInfo.data?.length && (
                    <CustomerOrPayerDropdown
                      dataScope="customerIds"
                      name="customerSelect"
                      onChange={handleAddCustomer}
                      options={
                        customerAdminQueryInfo.data?.map((data) => ({
                          label: data.customerNumber || '',
                          subLabel: data.customerName || '',
                          value: data.customerId || '',
                          disabled: !!groupedRoleAssignment.variantDataScopes.customersScopes.find(
                            (scope) => scope.customerId === data.customerId
                          )
                        })) || []
                      }
                    />
                  )}
                <Hauliers
                  groupedRoleAssignment={groupedRoleAssignment}
                  handleDeleteHaulier={handleDeleteHaulier}
                  isEditing
                />
                <Payers
                  groupedRoleAssignment={groupedRoleAssignment}
                  handleDeletePayer={handleDeletePayer}
                  setShowAnotherCustomerOrPayerDropdown={setShowAnotherCustomerOrPayerDropdown}
                  isEditing
                />
                {!showAnotherCustomerOrPayerDropdown &&
                  groupedRoleAssignment.variantDataScopes.payerIds &&
                  groupedRoleAssignment.variantDataScopes.payerIds.length > 0 &&
                  payerAdminQueryInfo.data &&
                  payerAdminQueryInfo.data?.length > 1 && (
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<AddIcon />}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        textTransform: 'none',
                        marginTop: '8px'
                      }}
                      onClick={() => setShowAnotherCustomerOrPayerDropdown(true)}
                    >
                      {t('roleAssignment.addOtherPayers')}
                    </Button>
                  )}
                {showAnotherCustomerOrPayerDropdown &&
                  payerAdminQueryInfo.data &&
                  (groupedRoleAssignment.variantDataScopes.customersScopes === undefined ||
                    groupedRoleAssignment.variantDataScopes.customersScopes.length === 0) &&
                  groupedRoleAssignment.variantDataScopes.payerIds &&
                  groupedRoleAssignment.variantDataScopes.payerIds?.length <
                    payerAdminQueryInfo.data?.length && (
                    <CustomerOrPayerDropdown
                      dataScope="payerIds"
                      name="payerSelect"
                      onChange={handleAddPayer}
                      options={
                        payerAdminQueryInfo.data?.map((data) => ({
                          label: data.payerNumber || '',
                          subLabel: data.payerName || '',
                          value: data.payerId || '',
                          disabled: !!groupedRoleAssignment.variantDataScopes.payerIds?.find(
                            (payerId) => payerId === data.payerId
                          )
                        })) || []
                      }
                    />
                  )}
                <Plants
                  groupedRoleAssignment={groupedRoleAssignment}
                  handleDeletePlant={handleDeletePlant}
                  isEditing
                />
                <CommentCategories
                  groupedRoleAssignment={groupedRoleAssignment}
                  isEditing
                  handleDeleteCommentCategory={handleDeleteCommentCategory}
                />
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button variant="text" color="primary" onClick={handleDiscard}>
                  {t('roleAssignment.discardChanges')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  data-test-id="button-role-save"
                  disabled={form.isSubmitting}
                >
                  {t('roleAssignment.save')}
                </Button>
              </CardActions>
            </Form>
          </RoleCard>
        )
      }
    </Formik>
  )
}
