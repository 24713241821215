import {getBusinessLines, getCountries, getOrgUnits} from '@hconnect/apiclient'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../../App.store'
import {DropdownOption} from '../../../common/DropdownOption'
import {useAdminPilotUser} from '../../../common/hooks/useAdminPilotUser'
import {RoleOptions} from '../RoleEditLayout'

export const useCustomerAdminOptions = (
  selectedOptions: RoleOptions,
  setSelectedOptions: (options: RoleOptions) => void
) => {
  const isUserAdminPilot = useAdminPilotUser()

  const {data: countryIdOptions, refetch: refetchCountries} = useQuery<
    DropdownOption[],
    AxiosError
  >(
    ['countryId'],
    async () => {
      const response = await getCountries(api)()
      const countries = response.data

      return countries
        .map((country) => ({
          key: country.countryCode,
          label: country.countryCode
        }))
        .slice(0, 1)
        .filter((item, index, self) => index === self.findIndex((t) => t.key === item.key))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    {
      enabled: isUserAdminPilot,
      onSuccess: (value) => {
        setSelectedOptions({
          ...selectedOptions,
          countryId: {isSingle: value.length === 1, dropdownOptions: value.length > 1 ? value : []}
        })
      }
    }
  )

  const {data: businessLineOptions} = useQuery<DropdownOption[], AxiosError>(
    ['businessLine'],
    async () => {
      if (!countryIdOptions?.[0].key) {
        return []
      }

      const response = await getBusinessLines(api)(
        selectedOptions.countryId.isSingle
          ? countryIdOptions?.[0].key
          : selectedOptions.countryId.value
      )

      return response.data
        .map((businessLine) => ({
          key: businessLine.businessLineCode,
          label: businessLine.businessLineName
        }))
        .filter((item, index, self) => index === self.findIndex((t) => t.key === item.key))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    {
      enabled: selectedOptions.countryId.isSingle ? true : !!selectedOptions.countryId.value,
      onSuccess: (value) => {
        setSelectedOptions({
          ...selectedOptions,
          businessLine: {
            isSingle: value.length === 1,
            dropdownOptions: value.length > 1 ? value : []
          }
        })
      }
    }
  )

  const {data: orgUnitIdOptions, refetch: refetchOrgUnit} = useQuery<DropdownOption[], AxiosError>(
    ['orgUnitId'],
    async () => {
      if (!countryIdOptions?.[0].key) {
        return []
      }

      const response = await getOrgUnits(api)(
        selectedOptions.countryId.isSingle
          ? countryIdOptions?.[0].key
          : selectedOptions.countryId.value,
        {
          businessLine: selectedOptions.businessLine.isSingle
            ? businessLineOptions?.[0].key || ''
            : selectedOptions.businessLine.value || ''
        }
      )
      return response.data
        .map((orgUnit) => ({
          key: orgUnit.orgUnitId,
          label: orgUnit.orgUnitName
        }))
        .filter((item, index, self) => index === self.findIndex((t) => t.key === item.key))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    {
      enabled: selectedOptions.businessLine.isSingle ? true : !!selectedOptions.businessLine.value,
      onSuccess: (value) => {
        setSelectedOptions({
          ...selectedOptions,
          orgUnitId: {isSingle: value.length === 1, dropdownOptions: value.length > 1 ? value : []}
        })
      }
    }
  )

  return {
    countryIdOptions,
    businessLineOptions,
    orgUnitIdOptions,
    refetchCountries,
    refetchOrgUnit
  }
}
