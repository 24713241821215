import {FormikProps, getIn} from 'formik'
import React from 'react'

import {useAdminPilotUser} from '../../../common/hooks/useAdminPilotUser'
import {
  DataScopeConfiguration,
  useRolesConfigurationForUserId
} from '../../../common/hooks/useRolesConfiguration'
import {
  DYNAMIC_SELECT_DATA_SCOPE_TYPE,
  LOOKUP_DATA_SCOPE_TYPE,
  READONLY_DATA_SCOPE_TYPE,
  TEXT_DATA_SCOPE_TYPE,
  getDataScopeConfiguration,
  sortDataScopes
} from '../../../modules/DataScopeConfiguration'
import {GroupedRoleReducerAction} from '../../../modules/GroupedRoleAssignmentReducer'
import {GroupedRoleAssignment} from '../../../modules/ManageUsers.selectors'
import {RoleOptions} from '../RoleEditLayout'

import {DynamicSelectDataScope} from './DynamicSelectDataScope'
import {LookupDataScope} from './LookupDataScope'
import ReadonlyDataScope from './ReadonlyDataScope'
import {TextDataScope} from './TextDataScope'

interface Props {
  form: FormikProps<GroupedRoleAssignment>
  dispatchRoleStateUpdate: React.Dispatch<GroupedRoleReducerAction>
  groupedRoleAssignment: GroupedRoleAssignment
  setShowCustomerDropdown: (show: boolean) => void
  showCustomerDropdown: boolean
  customerAdminData?: Record<string, string | undefined>
  selectedOptions: RoleOptions
  setSelectedOptions: (options: RoleOptions) => void
}

const isLookupDataScopeRequired = (
  isRequired: boolean,
  roleState: GroupedRoleAssignment,
  dataScopes?: DataScopeConfiguration[]
): boolean => {
  const payerIdsConfig = dataScopes?.find(
    (o) => o.dataScope === 'payerIds' && o.multiSelect === true
  )
  const customerIdsConfig = dataScopes?.find(
    (o) => o.dataScope === 'customerIds' && o.multiSelect === true
  )

  const contractIdsConfig = dataScopes?.find(
    (o) => o.dataScope === 'contractIds' && o.multiSelect === true
  )

  const isOneRequired =
    (payerIdsConfig && payerIdsConfig.isRequired) ||
    (customerIdsConfig && customerIdsConfig.isRequired)

  const payersOrCustomersIsRequired = Boolean(payerIdsConfig && customerIdsConfig && isOneRequired)

  if (!contractIdsConfig && payersOrCustomersIsRequired) {
    return false
  }

  return isRequired
}

const DataScopeFields: React.FC<Props> = ({
  form,
  dispatchRoleStateUpdate,
  groupedRoleAssignment,
  customerAdminData,
  setShowCustomerDropdown,
  showCustomerDropdown,
  selectedOptions,
  setSelectedOptions
}) => {
  const {rolesByType} = useRolesConfigurationForUserId()
  const isUserAdminPilot = useAdminPilotUser()

  if (!form.values.roleType) {
    return null
  }

  const handleDataScopeFilter = (dataScope: string) => {
    if (isUserAdminPilot) {
      if (
        selectedOptions.countryId.isSingle &&
        selectedOptions.businessLine.isSingle &&
        selectedOptions.orgUnitId.isSingle
      ) {
        return (
          dataScope !== 'countryId' && dataScope !== 'orgUnitId' && dataScope !== 'businessLine'
        )
      }

      if (selectedOptions.countryId.isSingle && selectedOptions.businessLine.isSingle) {
        return dataScope !== 'countryId' && dataScope !== 'businessLine'
      }

      if (selectedOptions.countryId.isSingle && selectedOptions.orgUnitId.isSingle) {
        return dataScope !== 'countryId' && dataScope !== 'orgUnitId'
      }

      if (selectedOptions.countryId.isSingle) {
        return dataScope !== 'countryId'
      }
    }

    return true
  }

  const dataScopes = rolesByType?.[form.values.roleType].dataScopes.filter(({dataScope}) =>
    handleDataScopeFilter(dataScope)
  )

  const sortedDataScopes = dataScopes?.slice().sort(sortDataScopes)
  return (
    <>
      {sortedDataScopes?.map(({dataScope: dataScopeName, isRequired, multiSelect}) => {
        const configuration = getDataScopeConfiguration(
          dataScopeName,
          isUserAdminPilot,
          form.values.roleType
        )
        const fieldName = `commonDataScopes.${dataScopeName}`
        const errorMessage = form.submitCount > 0 ? getIn(form.errors, fieldName) : null

        if (configuration.type === DYNAMIC_SELECT_DATA_SCOPE_TYPE) {
          return (
            <DynamicSelectDataScope
              key={fieldName}
              form={form}
              errorMessage={errorMessage}
              configuration={configuration}
              dataScopeName={dataScopeName}
              isRequired={isRequired}
              dispatchRoleStateUpdate={dispatchRoleStateUpdate}
              multiSelect={multiSelect}
              shouldDisplayAllInDropdown={false}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              groupedRoleAssignment={groupedRoleAssignment}
            />
          )
        }
        if (configuration.type === LOOKUP_DATA_SCOPE_TYPE) {
          return (
            <LookupDataScope
              key={fieldName}
              errorMessage={errorMessage}
              configuration={configuration}
              groupedRoleAssignment={groupedRoleAssignment}
              dataScopeName={dataScopeName}
              isRequired={isLookupDataScopeRequired(isRequired, groupedRoleAssignment, dataScopes)}
              dispatchRoleStateUpdate={dispatchRoleStateUpdate}
              multiSelect={multiSelect}
              customerAdminData={customerAdminData}
              setShowCustomerOrPayerDropdown={setShowCustomerDropdown}
              showCustomerOrPayerDropdown={showCustomerDropdown}
            />
          )
        }
        if (configuration.type === TEXT_DATA_SCOPE_TYPE) {
          return (
            <TextDataScope
              key={fieldName}
              errorMessage={errorMessage}
              dataScopeName={dataScopeName}
              multiSelect={multiSelect}
              isRequired={isRequired}
              dispatchRoleStateUpdate={dispatchRoleStateUpdate}
            />
          )
        }
        if (configuration.type === READONLY_DATA_SCOPE_TYPE) {
          return (
            <ReadonlyDataScope
              key={fieldName}
              errorMessage={errorMessage}
              dataScopeName={dataScopeName}
              multiSelect={multiSelect}
              isRequired={isRequired}
            />
          )
        }
        return (
          <input
            type="hidden"
            key={dataScopeName}
            name={`dataScope.${dataScopeName}`}
            value={form.values.commonDataScopes[dataScopeName] || ''}
          />
        )
      })}
    </>
  )
}

export default DataScopeFields
